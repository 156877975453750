import React from 'react';
import Helmet from 'react-helmet';

export default ({
	metaTitle = 'Library Street Collective',
	metaDescription,
	metaKeywords,
	openGraphTitle = 'Library Street Collective',
	openGraphDescription,
	openGraphImage,
	twitterTitle = 'Library Street Collective',
	twitterDescription,
	twitterImage,
	pathname = '/',
	siteName = 'Library Street Collective',
	siteUrl = 'https://www.lscgallery.com',
}) => (
	<Helmet>
		{metaTitle && <title>{metaTitle}</title>}
		{metaDescription && (
			<meta name="description" content={metaDescription} />
		)}
		<meta name="keywords" content={metaKeywords} />

		<meta property="og:url" content={`${siteUrl}${pathname}`} />
		<meta property="og:title" content={openGraphTitle} />
		<meta property="og:site_name" content={siteName} />
		<meta property="og:description" content={openGraphDescription} />
		{openGraphImage && (
			<meta
				property="og:image"
				content={`${openGraphImage.url}?w=1200&h=630`}
			/>
		)}

		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:title" content={twitterTitle} />
		<meta name="twitter:description" content={twitterDescription} />
		<meta name="twitter:url" content={`${siteUrl}${pathname}`} />
		{twitterImage && (
			<meta
				name="twitter:image:src"
				content={`${twitterImage.url}?w=1200&h=630`}
			/>
		)}
	</Helmet>
);

