import React, { Component } from 'react';
import classNames from 'classnames';
import viewport from 'magic-tricks/lib/viewport';

const INITIAL_STATE = {
  isRevealed: false,
};

export default class Reveal extends Component {
  constructor() {
    super();

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount = () => {
    viewport.listen(this.onScroll);
    this.onScroll();
  };

  onScroll = () => {
    if (!this.el) return;

    const { threshold, timeout } = this.props;
    const position = this.el.getBoundingClientRect();

    if (
      (position.top >= 0 && position.top <= global.innerHeight - threshold) ||
      (position.top <= 0 && position.bottom >= -threshold)
    ) {
      setTimeout(() => {
        this.onReveal();
      }, timeout);
    } else {
      setTimeout(() => {
        this.onHide();
      }, timeout);
    }
  };

  onReveal = () => {
    this.setState({ isRevealed: true });

    if (!this.props.reset) {
      viewport.unlisten(this.onScroll);
    }
  };

  onHide = () => {
    if (!this.props.reset) return;

    this.setState({
      isRevealed: false,
    });
  };

  render() {
    const { className, children, reset, ...props } = this.props; // eslint-disable-line
    const { isRevealed } = this.state;

    return (
      <section
        {...props}
        ref={el => (this.el = el)}
        className={classNames(className, 'reveal', {
          active: isRevealed,
        })}
      >
        {children}
      </section>
    );
  }
}

Reveal.defaultProps = {
  timeout: 0,
  threshold: 0,
};
